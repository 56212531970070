import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter)
const routes = [
	// s1增项
	{
		path: '/',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/login.vue')
	},
	//首页
	{
		path: '/home',
		name: 'home',
		component: () => import( /* webpackChunkName: "about" */ '../views/HomeView.vue')
	},
	//关于我们
	{
		path: '/about',
		name: 'about',
		component: () => import( /* webpackChunkName: "about" */ '../views/about.vue')
	},
	//产品详情(门窗)
	{
		path: '/productdetail',
		name: 'productdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/productdetail.vue')
	},
	//详情(配件)
	{
		path: '/cailiao_peijian',
		name: 'cailiao_peijian',
		component: () => import( /* webpackChunkName: "about" */ '../views/cailiao_peijian.vue')
	},
	
	
	//个人中心
	{
		path: '/selfcenter',
		name: 'selfcenter',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/selfcenter.vue')
	},
	//地址列表
	{
		path: '/address',
		name: 'address',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/address.vue')
	},
	//添加地址
	{
		path: '/addAddress',
		name: 'addAddress',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/addAddress.vue')
	},
	//修改密码
	{
		path: '/editpassword',
		name: 'editpassword',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/editpassword.vue')
	},
	//修改邮箱
	{
		path: '/editphone',
		name: 'editphone',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/editphone.vue')
	},
	//购物车
	{
		path: '/goodscart',
		name: 'goodscart',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/goodscart.vue')
	},
	//确认订单
	{
		path: '/shopSettle',
		name: 'shopSettle',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/shopSettle.vue')
	},
	//订单
	{
		path: '/order',
		name: 'order',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/order.vue')
	},
	//订单详情
	{
		path: '/orderdetail',
		name: 'orderdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/s1/orderdetail.vue')
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
//跳转后返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

export default router
