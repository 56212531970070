<template>
	<div class="hello">
		<div class="login_header">
			<div class="login_header_content">
				<div class="login_header_one" @click="headerclick(1)">
					<img src="../assets/logo.jpg" alt="" />
				</div>
				<div class="header_two">
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==1}"
						@click="headerclick(1)">
						<span>{{ $t('msg.msg2') }}</span>
					</div>
					<div class="header_two_list" :class="{'header_two_list_now':headerindex==2}"
						@click="headerclick(2)">
						<span>{{ $t('msg.msg3') }}</span>
					</div>
				</div>
				<div class="header_three">
					<el-select v-model="value" :placeholder="$t('msg.msg91')" @change="lanagechange">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="header_four" v-if="userInfo">
					<div class="header_four_one" @click="gouser">
						<img :src="userInfo.avatar" alt="" />
						<span>{{userInfo.nickname}}</span>
						<div class="header_four_one_border"></div>
					</div>
					<div class="header_four_two" @click="gocart">
						<img src="../assets/22.png" alt="" />
						<span>{{ $t('msg.msg92') }}</span>
						<div class="header_four_one_border"></div>
						<div class="cart_num" v-if="carttotal>0">
							<span>{{carttotal}}</span>
						</div>
					</div>
					<div class="header_four_two" style="padding-right: 20px;" @click="logincanfirem">
						<span>{{ $t('msg.msg93') }}</span>
					</div>
				</div>
				<div class="header_four" v-else>
					<div class="header_four_two" style="padding-right: 20px;color: #999;font-size: 24px;"
						@click="headerclick(3)">
						<span>{{ $t('msg.msg94') }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	export default {
		name: 'HelloWorld',
		data() {
			return {
				userInfo: "", //个人中心信息
				headerindex: 1, //头部状态
				ContactUs: "", //平台信息
				options: [{
					value: 'zh',
					label: '中文'
				}, {
					value: 'spa',
					label: 'España'
				}],
				value: 'zh',
				carttotal:0,
			}
		},
		mounted() {
			if (localStorage.getItem("headerindex")) {
				this.headerindex = localStorage.getItem("headerindex");
			} else {
				this.headerindex = 1;
			}
			if(localStorage.getItem("loginindex")){
				localStorage.removeItem("loginindex")
				window.location.reload()
			}
			if (localStorage.getItem("lang")) {
				this.value = localStorage.getItem("lang");
			} else {
				this.value = "zh";
			}
			if (localStorage.getItem("userInfo")) {
				this.get_goodscart();
				this.get_user_info();
			}
		},
		created() {
			this.getContactUs()
		},
		methods: {
			//获取父组件传过来的商品数量
			getcartnumber(num) {
				this.carttotal = num;
			},
			
			//语言切换
			lanagechange(e) {
				if (e == "zh") {
					localStorage.setItem('lang', "zh")
				} else {
					localStorage.setItem('lang', "spa")
				}
				window.location.reload();
			},

			//头部切换事件
			headerclick(index) {
				localStorage.setItem("headerindex", index);
				if (index == 1) {
					this.$router.push({
						"path": "/home"
					});
				} else if (index == 2) {
					this.$router.push({
						"path": "/about"
					})
				} else if (index == 3) {
					this.$router.push({
						"path": "/login"
					})
				}
			},

			//获取用户信息
			get_user_info() {
				this.$http({
					url: '5c78c4772da97',
					method: 'post',
					data: JSON.stringify({

					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.userInfo = res.data.data;
					}
				});
			},

			//获取购物车列表
			get_goodscart() {
				this.$http({
					url: '62bebd70c4bad',
					method: 'post',
					params: {},
				}).then((res) => {
					if (res.data.code == 1) {
						this.carttotal = res.data.data[0].cart_list.length;
					}
				});
			},

			//联系我们
			getContactUs() {
				this.$http({
					url: '661f907319645',
					method: 'post',
					data: JSON.stringify({

					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.ContactUs = res.data.data;
					}
				});
			},
			
			//跳转用户中心
			gouser(){
				localStorage.setItem("leftindex",1);
				this.$router.push({
					"path": "/selfcenter"
				})
			},
			
			//跳转购物车
			gocart(){
				this.$router.push({
					"path": "/goodscart"
				})
			},

			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},

			//退出登录确定事件
			logincanfirem() {
				this.$message({
					message:this.$t('msg.msg95'),
					type: 'success'
				});
				localStorage.clear();
				this.$router.push({
					path: "/"
				});
			},
		},
	}
</script>
<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.login_header {
		width: 100%;
		height: 80px;
		cursor: pointer;
		background-color: #FFFFFF
	}

	.login_header_content {
		width: 1300px;
		height: 100%;
		position: relative;
		margin: 0 auto;
	}

	.login_header_one {
		width: auto;
		height: 60px;
		font-weight: 600;
		font-size: 28px;
		color: #333333;
		float: left;
		margin-top: 10px;
		margin-right: 60px;
	}

	.login_header_one img {
		width: 90px;
		height: 45px;
		margin-right: 16px;
		display: block;
		float: left;
		margin-top: 12px;
	}

	.login_header_one_text {
		width: auto;
		height: auto;
		float: left;
	}

	.login_header_one_text h3 {
		font-weight: 600;
		font-size: 14px;
		color: #1A1A1A;
		margin-top: 4px;
	}

	.login_header_one_text p {
		font-weight: 400;
		font-size: 10px;
		color: #1A1A1A;
	}

	.header_two {
		width: auto;
		float: left;
	}

	.header_two_list {
		width: auto;
		font-weight: 400;
		font-size: 24px;
		color: #999999;
		float: left;
		margin-right: 30px;
		margin-top: 10px;
		line-height: 68px;
	}

	.header_two_list_now {
		font-weight: 500;
		font-size: 24px;
		color: #DD8E33;
		border-bottom: 3px solid #DD8E33;
	}

	.header_three {
		width:110px;
		height: 100%;
		float: right;
		line-height: 80px;
	}

	.header_four {
		width: auto;
		height: 100%;
		float: right;
		line-height: 80px;
		margin-right: 40px;
	}

	.header_four_one {
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		float: left;
		position: relative;
		padding-right: 40px;
	}

	.header_four_one img {
		width: 29px;
		height: 29px;
		border-radius: 50%;
		position: relative;
		top: 8px;
		margin-right: 6px;
	}

	.header_four_one_border {
		width: 1px;
		height: 20px;
		background: #CBCBCB;
		position: absolute;
		right: 0;
		top: 30px;
	}

	.header_four_two {
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		float: left;
		position: relative;
		padding-right: 30px;
		float: left;
		margin-left: 20px;
	}

	.header_four_two img {
		width: 17px;
		height: 17px;
		position: relative;
		top: 2px;
		margin-right: 4px;
	}

	.cart_num {
		width: 16px;
		height: 16px;
		background-color: red;
		border-radius: 50%;
		position: absolute;
		right: 20px;
		top: 20px;
		line-height: 16px;
		text-align: center;
		color: #FFFFFF;
		font-size: 14px;
	}
</style>