<template>
	<div class="home">
		<HelloWorld></HelloWorld>
		<div class="goods_content">
			<div class="goods_content_one">
				<div class="goods_content_one_l">
					<el-carousel height="940px">
						<el-carousel-item v-for="item in banner"> 
							<img :src="item.thumb" alt="" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="two">
			<div class="two_header">
				<div class="two_header_l">
					<span>{{ $t('msg.msg40') }}</span>
				</div>
				<div class="two_header_r">
					<div class="two_header_r_border"></div>
					<div class="two_header_r_list" :class="{'two_header_r_list_now':twoheadertype==1}" @click="twoheadetclick(1)">
						<span>{{ $t('msg.msg41') }}</span>
					</div>
					<div class="two_header_r_list" style="margin-right: 0;" :class="{'two_header_r_list_now':twoheadertype==2}" @click="twoheadetclick(2)">
						<span>{{ $t('msg.msg42') }}</span>
					</div>
					<div class="two_header_r_list" style="margin-left: 30px;" :class="{'two_header_r_list_now':twoheadertype==9}" @click="twoheadetclick(9)">
						<span>pvc</span>
					</div>
				</div>
			</div>
			<div class="two_content" v-if="twoheadertype==1">
				<div class="two_content_list" v-for="item in productlist" @click="mcdetail(item.aid)">
					<img :src="item.thumb" alt="" />
					<div class="two_content_list_content">
						<h3>{{item.name}}</h3>
						<p>{{item.desc}}</p>
						<div class="two_content_list_btn">
							<span>{{ $t('msg.msg43') }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 材料 -->
		<div class="three" v-if="twoheadertype==2||twoheadertype==9">
			<div class="three_header">
				<div class="three_header_list" :class="{'three_header_list_now':fxld==item.aid}" v-for="item in goodscategory" @click="flclick(item.aid)">
					<span>{{item.name}}</span>
				</div>
			</div>
			<div class="three_content">
				<div class="three_content_list" v-for="item in goods_lst" @click="cailiaodetail(item.aid,item.cate_type)">
					<img :src="item.thumb" alt="" />
					<div class="three_content_list_text">
						<span>{{item.name}}</span>
					</div>
					<div class="three_content_list_price">
						$<span>{{item.shop_price}}</span>
					</div>
				</div>
			</div>
			<noshuju v-if="totalnumber==0"></noshuju>
		</div>
		<div class="page-content">
			<el-pagination background layout="prev, pager, next" :total="totalnumber" :page-size="15" @current-change="pageclick"></el-pagination>
		</div>
		<footerBottom></footerBottom>
	</div>
</template>

<script>
	import HelloWorld from '@/components/HelloWorld.vue';
	import footerBottom from '@/components/footerBottom.vue';
	import noshuju from '@/components/noshuju.vue';
	export default {
		name: 'HomeView',
		components: {
			HelloWorld,
			footerBottom,
			noshuju
		},
		data() {
			return {
				banner:[],//轮播
				productlist:[],//门窗列表
				goodscategory:[],//材料分类
				fxld:"",//材料分类ID
				goods_lst:[],//材料列表
				totalnumber:0,//总条数
				pageindex:1,//页数
				twoheadertype:1,//产品列表头部状态
			}
		},
		computed: {
			
		},
		create() {},
		mounted() {
			this.get_operationads();//获取轮播图
			this.get_product();//查询窗户列表
			this.get_goodscategory();//查询材料分类
		},
		methods: {
			//获取轮播图
			get_operationads(){
				this.$http({
					url: '641e624160dd0',
					method: 'post',
					data: JSON.stringify({
						type:"1",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.banner = res.data.data;
					}
				});
			},
			
			//查询窗户列表
			get_product(){
				this.$http({
					url: '66504e4c60aa9',
					method: 'post',
					data: JSON.stringify({
						page:this.pageindex,
						list_rows:15,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.productlist = res.data.data.data;
						this.totalnumber = res.data.data.total;
					}
				});
			},
			
			//跳转窗户详情
			mcdetail(aid){
				this.$router.push({"path":"productdetail",query:{"aid":aid}});
			},
			
			//查询材料分类
			get_goodscategory(){
				let _this = this;
				this.$http({
					url: '63e369d925758',
					method: 'post',
					data: JSON.stringify({
					})
				}).then((res) => {
					if (res.data.code == 1) {
						_this.goodscategory = res.data.data;
						if(_this.goodscategory.length>0){
							_this.fxld = res.data.data[0].aid;
						}
					}
				});
			},
			
			//查询材料列表
			get_goods_lst(){
				this.$http({
					url: '64184ac1cafc3',
					method: 'post',
					data: JSON.stringify({
						cid:this.fxld,
						list_rows:15,
						page:this.pageindex,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.goods_lst = res.data.data.data;
						this.totalnumber = res.data.data.total;
					}
				});
			},
			
			//跳转材料详情
			cailiaodetail(aid,type){
				this.$router.push({"path":"cailiao_peijian",query:{"aid":aid,type:type}});
			},
			
			//分类切换
			flclick(aid){
				this.fxld = aid;
				this.pageindex =1;
				this.totalnumber = 0;
				this.get_goods_lst();
			},
			
			//页码切换
			pageclick(e){
				this.pageindex = e;
				if(this.twoheadertype==1){
					this.get_product();
				}else{
					this.get_goods_lst();
				}
			},
			
			//产品列表切换事件
			twoheadetclick(index){
				let _this = this;
				this.twoheadertype = index;
				this.pageindex = 1;
				this.totalnumber = 0;
				if(index==1){
					_this.get_product();
				}else if(index==2){
					_this.fxld = this.goodscategory[0].aid;
					_this.get_goods_lst();
				} else{
					_this.fxld = index;
					_this.get_goods_lst();
				}
			},
		}
	}
</script>
<style scoped>
	.home {
		width: 100%;
		background-color:#FAFAFA;
		overflow: hidden;
	}
	.goods_content {
		width:100%;
		overflow: hidden;
	}
	.goods_content_one {
		width: 100%;
		overflow: hidden;
	}
	.goods_content_one_l {
		width:100%;
		height:940px;
	}
	.goods_content_one_l img{
		width: 100%;
		height: 100%;
	}
	.two{
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
	}
	.two_header{
		width: 100%;
		height: 107px;
	}
	.two_header_l{
		width: auto;
		height: 100%;
		float: left;
		font-weight: 500;
		font-size: 28px;
		color: #333333;
		line-height: 107px;
	}
	.two_header_r{
		width: auto;
		height: 100%;
		float: right;
	}
	.two_header_r_border{
		width: 0px;
		height: 18px;
		border: 1px solid #D0D0D0;
		float: left;
		margin-top: 45px;
		margin-right: 24px;
	}
	.two_header_r_list{
		width: auto;
		overflow: hidden;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		float: left;
		cursor: pointer;
		margin-top: 43px;
		margin-right: 36px;
	}
	.two_header_r_list_now{
		font-weight: 500;
		font-size: 16px;
		color: #DD8E33;
	}
	.two_content{
		width: 100%;
		overflow: hidden;
	}
	.two_content_list{
		width: 590px;
		height: 435px;
		position: relative;
		float: left;
		cursor: pointer;
		margin-right: 20px;
		margin-bottom: 22px;
	}
	.two_content_list:nth-child(2n){
		margin-right: 0;
	}
	.two_content_list img{
		width: 100%;
		height: 100%;
	}
	.two_content_list_content{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.3);
	}
	.two_content_list_content h3{
		font-weight: 500;
		font-size: 24px;
		color: #FFFFFF;
		margin-top: 318px;
		text-indent: 24px;
	}
	.two_content_list_content p{
		font-weight: 400;
		font-size: 14px;
		color: #FFFFFF;
		margin-top: 5px;
		text-indent: 24px;
	}
	.two_content_list_btn{
		width: 62px;
		height: 24px;
		border: 1px solid #FFFFFF;
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
		text-align: center;
		line-height: 24px;
		margin-left: 24px;
		margin-top: 18px;
	}
	.three{
		width: 1200px;
		overflow: hidden;
		margin: 0px auto;
	}
	.three_header{
		width: 100%;
		overflow: hidden;
		background: #FFFFFF;
	}
	.three_header_list{
		width: auto;
		height: 100%;
		line-height: 56px;
		float: left;
		margin-right: 80px;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		text-indent: 24px;
		cursor: pointer;
	}
	.three_header_list_now{
		font-weight: 400;
		font-size: 16px;
		color: #DD8E33;
	}
	.three_content{
		width: 100%;
		overflow: hidden;
	}
	.three_content_list{
		width: 290px;
		height: 388px;
		background: #FFFFFF;
		float: left;
		margin-top: 15px;
		cursor: pointer;
		margin-right: 13px;
	}
	.three_content_list:nth-child(4n){
		margin-right: 0;
	}
	.three_content_list img{
		width: 258px;
		height: 258px;
		display: block;
		float: left;
		margin-top: 16px;
		margin-left: 16px;
	}
	.three_content_list_text{
		width: 258px;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		float: left;
		margin-top: 12px;
		margin-left: 16px;
	}
	.three_content_list_price{
		width: 258px;
		font-weight: bold;
		font-size: 12px;
		color: #F02325;
		float: left;
		margin-top: 18px;
		margin-left: 16px;
	}
	.three_content_list_price span{
		font-size: 20px;
	}
	.page-content{
		width: 1200px;
		overflow: hidden;
		text-align: right;
		margin: 40px auto;
	}
	/deep/ .page-content .el-pagination.is-background .el-pager li:not(.disabled).active{
		background-color: #DD8E33 !important;
	}
</style>